import './App.css';

function App() {

  function mapsSelector() {
    if /* if we're on iOS, open in Apple Maps */
      ((navigator.platform.indexOf("iPhone") !== -1) || 
       (navigator.platform.indexOf("iPad") !== -1) || 
       (navigator.platform.indexOf("iPod") !== -1))
      window.open("maps://maps.google.com/maps?daddr=40.988575866765025, 28.716653560200694&amp;ll=");
  else /* else use Google */
      window.open("https://maps.google.com/maps?daddr=40.988575866765025, 28.716653560200694&amp;ll=");
  }


  return (
    <div className='main'>
      <div className="header-container">
        <div className="names-vertical-container">
          <span className="name">BENAY</span>
          <div className="name-and">&</div>
          <span className="name">MESUT</span>
        </div>
      </div>

      <div className='our-story-container'>
        <img src="/images/our-story.jpeg"
          alt="Benay ve Mesut" className="our-story-image" />
      </div>

      <div className='invite-text-container'>
        <span className="invite-text">Sizi aramızda görmekten memnuniyet duyarız.</span>
        <time dateTime="2025-09-23" className="invite-date">29 Eylül, 2024</time>
        <span className="invite-text">13:00</span>
      </div>

      <div className="footer-image-container">
        <img src="/images/small-flower.png"
          alt="Benay ve Mesut" className="footer-image" />
      </div>

      <a onClick={mapsSelector}>
        <div className="navigation-container">
          <img src="/images/navigate.png" alt="navigasyon" className="navigate-icon" />
          <span className='navigation-text'>YOL TARİFİ</span>
        </div>
      </a>

    </div>
  );
}

export default App;
